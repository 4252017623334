














































import { Component, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import ReportFinances from "@/views/Report/ReportFinances.vue";
import ReportRegistrationChart from "@/views/Report/ReportRegistrationChart.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import { IRegistrationsPerDrivingSchoolReport } from "@/interfaces/IRegistrationsPerDrivingSchoolReport";
import eventBus from "@/plugins/EventBus";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";
import ReportPlanningFilter from "@/views/Report/ReportPlanningFilter.vue";

const LicenseClassModule = namespace("license-class");
const BranchModule = namespace("branch");
const InstructorModule = namespace("instructor");
const PaymentWorkFlowModule = namespace("payment-workflow");
const ReportModule = namespace("report");
const LearnModule = namespace("learn");

@Component({
  components: {
    FscSimpleCard,
    FscPageHeader,
    ReportFinances,
    ReportRegistrationChart,
    ReportPlanningFilter,
  },
})
export default class Planning extends Vue {
  public name = "Planning";

  @LicenseClassModule.Action("groupsList/findAll")
  public findAllLicenseClassGroups!: (options: ICrudOptions) => Promise<void>;

  @LicenseClassModule.Getter("groupsList/getDataList")
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @BranchModule.Action("findAll")
  public findAllBranches!: (options: ICrudOptions) => Promise<void>;

  @BranchModule.Getter("getDataList")
  public branches!: Array<IBranch>;

  @InstructorModule.Action("filter")
  public filterInstructors!: (options: ICrudOptions) => Promise<void>;

  @InstructorModule.Getter("getDataList")
  public instructors!: Array<IInstructor>;

  @ReportModule.Action("registrationsPerDrivingSchool/filter")
  public filterRegistrationsPerDrivingSchool!: (options: ICrudOptions) => Promise<void>;

  @ReportModule.Getter("registrationsPerDrivingSchool/getData")
  public registrationsPerDrivingSchool!: IRegistrationsPerDrivingSchoolReport;

  @ReportModule.Getter("registrationsPerDrivingSchool/getIsLoading")
  public registrationsPerDrivingSchoolLoading!: boolean;

  @PaymentWorkFlowModule.Action("findAll")
  public findAllPaymentWorkflows: any;

  @PaymentWorkFlowModule.Getter("getDataList")
  public allPaymentWorkflows: any;

  @LearnModule.Action("filter")
  public filterRegistrationsPerDrivingSchoolPie: any;

  @LearnModule.Getter("getDataList")
  public registrationsPerDrivingSchoolPie: any;

  public selectedRow: any = null;

  public filterInfo: any = null;

  public registrationData: any = null;

  public registrationPieData: any = null;

  public info: any = {};

  private reportPlanning: any = [
    {
      id: 2,
      name: this.$t("report.registrations"),
    },
    // {
    //   id: 3,
    //   name: this.$t("report.dropouts"),
    // },
  ];

  public async mounted(): Promise<void> {
    await this.findAllLicenseClassGroups({
      resource: "/license-class-groups",
    });

    await this.findAllBranches({
      resource: "/branches",
      params: { archived: false },
    });

    await this.filterInstructors({ resource: "instructor", filter: { archived: false, ...DefaultInstructorSorting } });

    await this.findAllPaymentWorkflows({
      resource: "payment-workflows",
    });
  }

  public onSelectedRow(row: IBasicNamedDTO): void {
    this.selectedRow = row;
    this.filterInfo = null;
    this.registrationData = null;
  }

  public extractValuesByPropFromObject(obj: any, propName: any, propName2?: any): string {
    if (!obj || !propName) return "";
    if (Array.isArray(obj)) {
      const value = obj.map((item: any) => item[propName] + " " + (item[propName2] || ""));
      if (value && value?.length > 0) {
        return value.join(", ");
      }
    } else if (Object.keys(obj) && Object.keys(obj).includes(propName)) {
      return obj[propName];
    }
    return "";
  }
  public extractStringFromFilters() {
    const { startDate, endDate, licenseClassGroupIds, instructorIds, branchIds } = this.filterInfo;
    const licenseClasses = this.extractValuesByPropFromObject(licenseClassGroupIds, "name");
    const instructors = this.extractValuesByPropFromObject(instructorIds, "firstName", "lastName");

    const branches = this.extractValuesByPropFromObject(branchIds, "location");
    this.info = { startDate, endDate, licenseClasses, instructors, branches };
    eventBus.$emit("selected-filter", this.info);
  }

  public async onSubmitFilterForm(filter: any, filterInfo: any): Promise<void> {
    this.filterInfo = filterInfo;
    this.extractStringFromFilters();
    if (this.selectedRow && this.selectedRow.id === 2) {
      await this.filterRegistrationsPerDrivingSchool({
        resource: "/reports/registrations-per-driving-school-by-month",
        filter: filter,
      });
      await this.filterRegistrationsPerDrivingSchoolPie({
        resource: "reports/registrations-per-driving-school-by-license-class",
        filter: filter,
      });
      this.registrationData = this.registrationsPerDrivingSchool ? this.registrationsPerDrivingSchool : null;
      this.registrationPieData = this.registrationsPerDrivingSchoolPie ? this.registrationsPerDrivingSchoolPie : null;
    }
  }
}
